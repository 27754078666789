import React from "react"
import { Link } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import LayoutForPageImage from "../components/Layouts/LayoutForPageImage/LayoutForPageImage"
import ImagePageStep2 from "../components/PageStep2/ImagePageStep2"
// import { Container } from 'react-bootstrap';
import LayoutTitle from "../components/Layouts/LayoutTitle/LayoutTitle"
import { Accordion, Card, Button, Alert } from "react-bootstrap"
// import Scheme2btn from "../components/PageStep2/Scheme2btn"
// import YouTubeButton from "../components/YouTubeButton/YouTubeButton"
// import ImageScheme2en from "../components/PageStep2/ImageScheme2en"
import ImageScheme2ru from "../components/PageStep2/ImageScheme2ru"

const IndexPage = (props) => {
  const { t } = useTranslation()

  return (
    <div>
      <SEO title="How to solve a Rubik's cube   Как собрать кубик Рубика" />
      <LayoutTitle><h1 >{t('step2.h1')}</h1></LayoutTitle>
      <LayoutForPageImage>
        {/* <a href={t("step2.videoUrl")} target="blank"> */}
          <ImagePageStep2 />
          {/* <YouTubeButton /> */}
        {/* </a> */}

        <Accordion defaultActiveKey="0" style={{ margin: '0 auto', textAlign: 'center'}}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="warning" style={{ display: 'block', margin: '0 auto', padding: "1rem", textAlign: 'center', letterSpacing: '1px'}} eventKey="1" >
                {t('nav.scheme')}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <ImageScheme2ru />
                  {/* <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
                    <Tab eventKey="home" title=" ">
                      <ImageScheme2ru />
                    </Tab> */}
                    {/* <Tab eventKey="profile" title={t('main.tabtitleEng')} style={{marginTop: '1.5rem'}}>
                    <ImageScheme2en />
                    </Tab> */}
                    {/* <Tab eventKey="contact" title="Contact" disabled>
                      <Sonnet />
                    </Tab> */}
                  {/* </Tabs> */}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
      </LayoutForPageImage>
      <div style={{ textAlign: 'center'}}>
       {/* <Scheme2btn /> */}
      </div>
      {/* Video */}
      <div className="container-iframe">
        <iframe
          style={{ display: 'block', padding: '1rem 0', margin: '0 auto'}}
          width="90%"
          height="600"
          title={t("step2.videoTitle")}
          src={t("step2.videoUrl")}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
      </div>
     

      {/* <Alert  variant="dark" style={{textAlign: 'center'}}>
        {t("main.link_to_social_chat")}<span role="img" aria-label="hand">👉</span>
       <Alert.Link href={t("step2.link_insta")} target="blank">{t("main.here")}</Alert.Link>
      </Alert> */}
      <Alert  variant="dark" style={{textAlign: 'center'}}>
        {t("main.link_to_social_chat")}<span role="img" aria-label="hand">👉</span>
       <Alert.Link href={t("main.link_telegram_chat")} target="blank">{t("main.here")}</Alert.Link>
      </Alert> 

      {/* Buttons prev/next */}
      
      <div style={{textAlign: 'center', padding: '1rem'}}>
        <Link to="/step-1/">
          <Button variant="secondary">
            &#171;{t('step2.btnprev')}
          </Button>{' '}
        </Link>

        {/* <Scheme2btn /> */}

        <Link to="/step-3/">
          <Button variant="secondary">
            {t('step2.btnnext')}&#187;
            
          </Button>{' '}
        </Link>
      </div>
    </div>
  )
}

export default IndexPage
